<template>
    <BasePopup v-bind="$attrs" v-on="$listeners">
        <div class="size-table">
            <div class="size-table__title">Таблица размеров одежды (см)</div>
            <div class="size-table__description">Общая таблица размеров показывает нашу стандартную размерную линейку.</div>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center"></th>
                            <th class="text-center">Грудь</th>
                            <th class="text-center">Талия</th>
                            <th class="text-center">Бёдра</th>
                            <th class="text-center">Рост</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(size, index) in filteredSizes">
                            <tr :key="`table-size-${ index }`">
                                <td class="text-center">{{ size.title }}</td>
                                <td class="text-center">{{ size.chest }}</td>
                                <td class="text-center">{{ size.waist }}</td>
                                <td class="text-center">{{ size.hips }}</td>
                                <td class="text-center">{{ size.height }}</td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import BasePopup from '../common/BasePopup';

    export default {
        name: 'PopupSizeTable',
        components: {
            BasePopup,
        },
        props: {
            genderId: {
                type: Number,
                default: 1
            }
        },
        computed: {
            ...mapState('sizes', {
                sizes: state => state.entities
            }),
            filteredSizes() {
                return this.sizes.filter(item => item.gender_id === this.genderId);
            }
        },
        data: () => ({
        }),
        methods: {
        }
    }
</script>

<style lang="scss">
.size-table {
    padding: 12px;
    position: relative;
    background: #FFFFFF;
    transition: .2s ease box-shadow;
    border-radius: 0;
    
    &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        overflow: hidden;
    }
    
    &__description {
        font-size: 14px;
        line-height: 17px;
        padding: 10px 0;
    }

    th, td {
        font-size: 16px !important;
    }
}
</style>