<template>
    <div class="product">
        <v-progress-circular
            indeterminate
            color="primary"
            v-show="!loaded"
            class="fixed__preloader"
        ></v-progress-circular>
        <div v-show="loaded">
            <div class="product__preloading">
                <img v-for="(item, index) in pictures" :key="`preloading-${ index }`" :src="item">
            </div>
            <div class="product__back" @click="back"><v-icon>mdi-arrow-left-thin</v-icon> Назад</div>
            <div class="product__container">
                <div class="product__picture">
                    <v-carousel
                        hide-delimiters>
                        <v-carousel-item
                            v-for="(item, index) in pictures"
                            :key="`picture-${ index }`"
                            :src="item"
                        >
                        </v-carousel-item>
                    </v-carousel>
                    <!-- <v-zoomer-gallery
                        style="width: 100vw;"
                        :list="pictures"
                        v-model="picIndex"
                    ></v-zoomer-gallery> -->
                </div>
                <div class="product__content">
                    <div>
                        <div class="product__code">{{ product.code }}</div>
                        <div class="product__content__wrap">
                            <div class="product__title">{{ product.title }}</div>
                            <div class="product__price">{{ priceFormated(product.price) }}</div>
                        </div>
                        <div class="product__description" v-if="product.description" v-html="htmlFormatted(product.description)"/>
                        <div class="product__sizes">
                            <div class="product__sizes__items">
                                <template v-for="(size, index) in product.sizes">
                                    <BaseButton :key="`size-${ index }`" :class="{ 'button-fill': selectedSize.id === size.id }" @click.stop="setSize(size)">{{ size.title }}</BaseButton>
                                </template>
                            </div>
                            <BaseButton class="button-fill product__sizes__table" @click.stop="popupSizeTableShown = true">Таблица размеров</BaseButton>
                        </div>
                        <div class="product__description" v-if="product.composition" v-html="htmlFormatted(product.composition)"/>
                        
                        <div class="product__fabrics" v-if="product.fabrics">
                            <div class="product__fabrics__item" v-for="(item, index) in product.fabrics" :key="`fabrics-${ index }`">
                                <div class="product__fabrics__item__title">Ткань - {{ item.title }}</div>
                                <div class="product__fabrics__item__picture" :style="`background-image: url(${imageSrc(item.picture)})`" v-if="item.picture"/>
                                <span v-html="item.description"/>
                            </div>
                        </div>
                    </div>
                    <div class="product__buttons__wrapper">
                        <div class="product__buttons">
                            <BaseCounter
                                :value="quantity"
                                :min-value="1"
                                @change="setQuantity($event)"
                            />
                            <BaseButton class="button-fill" @click.stop="addCartProduct">В корзину</BaseButton>
                        </div>
                    </div>
                </div>
            </div>
            <PopupSizeTable 
                v-model="popupSizeTableShown"
                :gender-id="product.gender_id"
                @close="popupSizeTableShown = false" />
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, htmlFormatted } from '../helpers';
    import BaseButton from '../components/common/BaseButton';
    import BasePopup from '../components/common/BasePopup';
    import BaseCheckbox from '../components/common/BaseCheckbox';
    import BaseRadio from '../components/common/BaseRadio';
    import BaseCounter from '../components/common/BaseCounter';
    
    import PopupSizeTable from '../components/entity/PopupSizeTable';

    export default {
        name: 'Entity',
        metaInfo() {
            return {
                title: this.product.title
            }
        },
        components: {
            BaseButton,
            BasePopup,
            BaseCheckbox,
            BaseRadio,
            BaseCounter,
            PopupSizeTable,
        },
        data: () => ({
            imageSrc, 
            priceFormated, 
            htmlFormatted,
            loaded: false,
            selectedSize: {},
            quantity: 1,
            popupSizeTableShown: false,
            picIndex: 0
        }),
        computed: {
            ...mapState('products', {
                product: state => state.entity
            }),
            pictures() {
                if(this.product.pictures) {
                    return [
                        this.product.picture,
                        ...this.product.pictures
                    ].map(item => imageSrc(item))
                }
                return [
                    this.product.picture
                ].map(item => imageSrc(item))
            },
        },
        async mounted() {
            const { code } = this.$route.params;
            await store.dispatch('sizes/fetch');
            await store.dispatch('products/getByCode', { code });
            this.selectedSize = this.product.sizes[0];
            this.loaded = true;
        },
        methods: {
            setQuantity(quantity) {
                this.quantity = quantity;
            },
            addCartProduct() {
                this.$emit('updateEntity');
                store.dispatch('orders/addCartProduct', { 
                    product: {
                        ...this.product,
                        quantity: this.quantity,
                        unique_code: `${ this.product.id }-${ this.selectedSize.id }`
                    },
                    size: this.selectedSize,
                });
            },
            setSize(size) {
                this.selectedSize = size;
            },
            back() {
                this.$router.push(`/category/${ this.product.categories[0].parent.code }`);
            }
        }
    }
</script>

<style lang="scss">
.product {
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    &__preloading {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
    
    &__back {
        margin: 10px 0;
        cursor: pointer;
    }

    &__container {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
            
        @media screen and (max-width: 690px) {
            display: block;
        }
    }

    &__picture {
        width: 100%;
        max-width: 500px;
        max-height: 750px;
        overflow: hidden;
        border-radius: 7px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
            
        @media screen and (max-width: 1100px) {
            max-width: 400px;
            max-height: 600px;
        }
            
        @media screen and (max-width: 690px) {
            width: 100%;
            margin-top: 24px;
        }

        .v-carousel,
        .v-carousel__item {
            height: 100% !important;
        }
        .v-carousel__controls {
            background: #ffffff5d;

            .v-btn.v-btn--icon {
                width: 22px;
                height: 22px;
                background-color: #F36223;
            }
            .v-btn--icon.v-size--small .v-icon, .v-btn--fab.v-size--small .v-icon {
                font-size: 15px !important;
            }
        }
        .v-responsive__content {
            width: 500px;
            max-width: 100%;
            height: 750px;
            max-height: calc((100vw - 20px) * 3 / 2);
            
            @media screen and (max-width: 1100px) {
                width: 400px;
                height: 600px;
            }
        }
        .v-btn.v-btn--icon {
            background-color: #F36223;
        }
    }

    &__content {
        width: calc(100% - 520px);
        min-height: 100%;
        padding: 0 4px;
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
            
        @media screen and (max-width: 1100px) {
            width: calc(100% - 420px);
        }
            
        @media screen and (max-width: 690px) {
            width: 100%;
        }

        &__wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }
        
    &__code {
        font-size: 14px;
        line-height: 20px;
    }
    
    &__title {
        font-size: 22px;
        line-height: 28px;
    }
    
    &__price {
        display: inline-block;
        position: relative;
        padding: 5px 30px;
        background: #F36223;
        font-weight: 400;
        white-space: nowrap;
        font-size: 20px;
        line-height: 22px;
        color: #ffffff;
        text-align: center;
        border-radius: 5px 0;
    }

    &__sizes {
        padding-top: 10px;

        &__items {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
        }

        .v-btn {
            width: 60px;
            margin: 10px 10px 0 0;
        }

        &__table.v-btn {
            width: 200px;
        }
    }

    &__fabrics {
        padding-top: 20px;

        &__item {
            padding: 10px 0;
            font-size: 16px;
            line-height: 20px;
            
            &__title {
                font-size: 20px;
                line-height: 26px;
                padding-bottom: 10px;
            }

            &__picture {
                margin: 0 10px 10px 0;
                width: 100px;
                height: 100px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                float: left;
            }
        }
    }
    
    &__description {
        font-size: 16px;
        line-height: 20px;
        padding-top: 20px;
    }
    
    &__weight {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 16px;
        line-height: 19px;
    }

    &__groups {
        border-bottom: 1px solid #efefef;

        .v-input--radio-group {
            margin: 0;
            padding: 0;
        }

        &__item {
            padding: 10px 0;
            border-top: 1px solid #efefef;
        }
        &__title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 5px;
            color: #F36223;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 550px;
        padding-top: 20px;

        &__wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        > .base-counter {
            width: 150px;
        }
        > .base-button {
            width: calc(100% - 170px);
        }
    }
}
</style>
